import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';

/*

Editable cell has its own value for input
Then when confirmation is clicked, update the state



*/

function EditableCell({ person, value, valueName, editCallback }) {

    const [editing, setEditing] = useState(false);
    const [myValue, setMyValue] = useState(value);

    const startEditing = () => {
        setMyValue(value);
        setEditing(true);
    }

    const confirmChange = () => {
        editCallback(person.id, valueName, parseInt(myValue));
        setEditing(false);
    }

    if (editing) {
        return (
            <React.Fragment>
                <TextField
                    type="number"
                    value={myValue}
                    onChange={(event) => setMyValue(event.target.value)}
                />
                <IconButton onClick={() => setEditing(false)}><ClearIcon /></IconButton>
                <IconButton onClick={() => confirmChange()}><DoneIcon /></IconButton>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                {value} <IconButton onClick={() => startEditing()}><EditIcon /></IconButton>
            </React.Fragment>
        )
    }
}

export default EditableCell
