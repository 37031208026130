import React from 'react'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AddPerson({ addCallback }) {
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({
        name: '',
        payed: 0
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setValues({ name: '', payed: 0 });
        setOpen(false);
    }

    function handleSubmit() {
        addCallback(values.name, values.payed);
        setOpen(false);
        setValues({ name: '', payed: 0 });
    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add person
      </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add person</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill in the name of the person and the amount the person payed.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange('name')}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="payed"
                        label="Payed"
                        type="number"
                        value={values.payed}
                        onChange={handleChange('payed')}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Add person
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddPerson
