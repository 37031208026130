import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import uuid from 'uuid';

import './App.css';
import Person from './Components/Person';
import OweMessage from './Components/OweMessageBox';
import OweMessageBox from './Components/OweMessageBox';
import PersonsTable from './Components/PersonsTable';
import AddPerson from './AddPerson';
import Header from './Components/Header';

/*

  {
    "name": "",
    "paid": ""
  }

 */

/*

const cars = ['red', 'green'];
const trucks = ['redtruck', 'greentruck'];

// Method 1: Concat 
const combined1 = [].concat(cars, trucks);

// Method 2: Spread
const combined2 = [...cars, ...trucks];

*/

//const [] = useState(0);

/*

var newPerson = { "name": "Felix", "payed": "100" };
setPersons([persons, ...newPerson]);

*/


/*
 
Felix: 300
Jonatan: 200
Alex: 50
Robert: 100
Total: 650
Per person: 162,5

Actually, need to divide each individual payment, then remove the payers amount
For example: 300/4=75 300-75=225

Calculate the percentage, for example 1/4 where 4 is number of persons. Then take that result and times persons - 1
 
*/

// Check traversy video on Todo-list (intro to react 2019) on how to lift the state up
// Will probably need to filter the person and change the value of payed, then change the state

function App() {

  /*const [persons, setPersons] = useState([
    {
      id: uuid.v4(),
      name: "Felix",
      payed: 1700
    },
    {
      id: uuid.v4(),
      name: "Anna",
      payed: 800
    },
    {
      id: uuid.v4(),
      name: "Alex",
      payed: 0
    },
    {
      id: uuid.v4(),
      name: "Jonatan",
      payed: 0
    },
    {
      id: uuid.v4(),
      name: "Amanda",
      payed: 0
    },
    {
      id: uuid.v4(),
      name: "Robert",
      payed: 0
    }
  ]);*/

  const [persons, setPersons] = useState(JSON.parse(localStorage.getItem('persons')) || [
    {
      id: uuid.v4(),
      name: "Felix",
      payed: 1700
    },
    {
      id: uuid.v4(),
      name: "Anna",
      payed: 800
    },
    {
      id: uuid.v4(),
      name: "Alex",
      payed: 0
    },
    {
      id: uuid.v4(),
      name: "Jonatan",
      payed: 0
    },
    {
      id: uuid.v4(),
      name: "Amanda",
      payed: 0
    },
    {
      id: uuid.v4(),
      name: "Robert",
      payed: 0
    }
  ]);

  //const [totalAmount, setTotalAmount] = useState(persons.reduce((acc, person) => acc + person.payed, 0));
  //const [amountPerPerson, setAmountPerPerson] = useState(totalAmount / persons.length);

  //let totalAmount = persons.reduce((acc, person) => acc + person.payed, 0);

  let totalAmount = 0;

  persons.forEach((person) => {
    totalAmount += person.payed;
  });

  const amountPerPerson = Math.round(totalAmount / persons.length);

  /*const updateTotals = () => {
    setTotalAmount(persons.reduce((acc, person) => acc + person.payed, 0));
    setAmountPerPerson(totalAmount / persons.length);

    console.log("totalAmount: " + totalAmount);
    console.log("amountPerPerson: " + amountPerPerson);
  }*/

  const updateOwes = (newPersons = persons) => {

    //updateTotals();

    const tempPersons = newPersons.map((person, index) => {
      let owed = 0;
      let owes = 0;

      if (amountPerPerson > person.payed) {
        owes = amountPerPerson - person.payed;
      }
      else if (amountPerPerson < person.payed) {
        owed = person.payed - amountPerPerson;
      }

      person.owes = Math.round(owes);
      person.owed = Math.round(owed);

      return person;
    });
    console.log(tempPersons);
    setPersons(tempPersons);
  };

  const editCallback = (personID, valueName, value) => {

    console.log("updated " + valueName + " to " + value);

    const tempPersons = persons.map(person => {
      if (person.id === personID) {
        person.payed = parseInt(value);
      }
      return person;
    });

    updateOwes(tempPersons);
  }

  const addCallback = (newName, payedValue) => {

    const newPerson = {
      id: uuid.v4(),
      name: newName,
      payed: parseInt(payedValue)
    };

    const newPersons = [...persons, newPerson];

    updateOwes(newPersons);
  }

  const deleteCallback = (id) => {

    const newPersons = [...persons.filter(person => person.id !== id)]

    updateOwes(newPersons);
  }

  useEffect(() => { updateOwes() });

  useEffect(() => {
    localStorage.setItem('persons', JSON.stringify(persons));
  }, [persons]);

  return (
    <div className="App">
      <Paper>

        <Header totalAmount={totalAmount} amountPerPerson={amountPerPerson} />
        <AddPerson addCallback={addCallback} />
        <PersonsTable persons={persons} editCallback={editCallback} deleteCallback={deleteCallback} />
        <OweMessageBox persons={persons} />
      </Paper>
    </div >
  );
}

export default App;
