import React from 'react'
import Button from '@material-ui/core/Button';

/*



*/

function Person({ person, totalAmount }) {

    return (
        <tr>
            <td>{person.name}</td>
            <td>{person.payed}</td>
            <td>{person.owed}</td>
            <td>{person.owes}</td>
        </tr>
    )
}

export default Person
