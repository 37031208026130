import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EditableCell from './EditableCell';

function PersonRow({ person, editCallback, deleteCallback }) {

    return (
        <TableRow key={person.name}>
            <TableCell component="th" scope="row">
                {person.name}
            </TableCell>
            <TableCell align="right"> <EditableCell person={person} value={person.payed} valueName="payed" editCallback={editCallback} /> </TableCell>
            <TableCell align="right">{person.owed}</TableCell>
            <TableCell align="right">{person.owes}</TableCell>
            <TableCell align="right"><IconButton onClick={() => deleteCallback(person.id)} edge="end" aria-label="Delete">
                <DeleteIcon />
            </IconButton></TableCell>
        </TableRow>
    )
}

export default PersonRow
