import React from 'react';

function Header({ totalAmount, amountPerPerson }) {

    return (
        <React.Fragment>
            <h1>Total payed: {totalAmount}</h1>
            <p>Amount per person: {amountPerPerson}</p>
        </React.Fragment>
    )
}

export default Header
