import React from 'react';
import OweMessage from './OweMessage';

function OweMessageBox({ persons }) {


    function owesCompare(a, b) {
        const owesA = a.owes;
        const owesB = b.owes;

        let comparison = 0;
        if (owesA > owesB) {
            comparison = 1;
        } else if (owesA < owesB) {
            comparison = -1;
        }
        return comparison * -1;
    }

    function owedCompare(a, b) {
        const owedA = a.owed;
        const owedB = b.owed;

        let comparison = 0;
        if (owedA > owedB) {
            comparison = 1;
        } else if (owedA < owedB) {
            comparison = -1;
        }
        return comparison * -1;
    }

    const recievers = persons.filter(person => person.owes === 0);
    const owers = persons.filter(person => person.owed === 0);

    recievers.sort(owedCompare);
    owers.sort(owesCompare);

    let oweMessages = [];

    const createMessage = (reciever, ower, amount) => {
        let newMessage = ower.name + " owes " + reciever.name + " " + amount;
        console.log(newMessage);
        oweMessages.push(newMessage);
    };

    /*
    
      Felix is owed 137,5
      Alex owes 112,5
  
      Looping through the recievers and then owers yields:
      Check if the ower owes more than what the reciever is owed. If so, subtract the amount from the ower and set reciever to 0, then break.
      If not, check if reciever is owed more than the ower. If so, subtract the amount from the reciever and set ower to 0.
  
    */

    recievers.map((reciever) => {
        console.log(reciever.name);
        owers.map((ower) => {
            if (reciever.owed > 0 && ower.owes > 0) {

                if (ower.owes < reciever.owed) { // The ower owes less than what the reciever is owed.
                    let amount = ower.owes;
                    console.log("owes < owed " + amount);
                    reciever.owed -= amount;
                    ower.owes = 0;
                    createMessage(reciever, ower, amount);
                }

                else if (ower.owes > reciever.owed) { // The ower owes more than what the reciever is owed.
                    let amount = reciever.owed;
                    console.log("owes > owed " + amount);
                    ower.owes -= amount;
                    reciever.owed = 0;
                    createMessage(reciever, ower, amount);
                }

                else { // Equals, meaning they cancel out
                    let amount = ower.owes;
                    reciever.owed = 0;
                    ower.owes = 0;
                    createMessage(reciever, ower, amount);
                }
            }
        });
    });

    return (
        <React.Fragment>
            {oweMessages.map((message, index) => (<OweMessage key={index} message={message} />))}
        </React.Fragment>
    )
}

export default OweMessageBox
